@import url("https://fonts.googleapis.com/css?family=Nunito|Oswald");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  margin: 0;
}
.savings-amount sup,
.price-amount sup {
  font-size: 26px;
  padding-left: 4px;
  min-width: 40px;
  display: inline-block;
  position: relative;
  line-height: 20px;
  min-height: 32px;
  text-align: center;
}

.price-amount sup.FFFFFF.no-dash,
.price-amount sup.no-dash {
  font-size: 22px;
  top: 2px;
  padding-left: 4px;
  min-width: 0px;
  display: inline-block;
  position: relative;
  line-height: 20px;
  min-height: 32px;
  text-align: center;
}
.savings-amount sup.FFFFFF.no-dash,
.savings-amount sup.no-dash {
  font-size: 16px;
  padding-left: 4px;
  min-width: 0px;
  display: inline-block;
  position: relative;
  line-height: 20px;
  min-height: 32px;
  text-align: center;
}
.price-amount sup.FFFFFF.no-dash:after,
.savings-amount sup.FFFFFF.no-dash:after,
.price-amount sup.no-dash:after,
.savings-amount sup.no-dash:after {
  display: none;
}

.savings-amount sup:after,
.price-amount sup:after {
  background: black;
  display: block;
  position: absolute;
  top: 80%;
  left: 10px;
  right: 10px;
  height: 3px;
  content: "";
}

.savings-amount sup.FFFFFF:after,
.price-amount sup.FFFFFF:after {
  background: white;
  display: block;
  position: absolute;
  top: 80%;
  left: 10px;
  right: 10px;
  height: 3px;
  content: "";
}

.filter label.active{
  font-weight: bold;
}

.filter label.active p{
  background-color: black;
  color: white;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
  position: fixed;
  overflow: hidden;
  z-index: 100;
}

.display-none {
  display: none;
}

.offerHeader {
  font-size: 22px;
  font-family: Oswald, sans-serif;
  margin: 0;
}

.offerText {
  clear: both;
  font-size: 14px;
}

.priceAmount{font-size: 30px;}
.priceAmount.right {
    padding: 15px 20px 10px 50px;
}
.priceAmount.left {
    padding: 12px 50px 10px 20px;
}

.savings-amount {
  font-size: 32px;
}

.mainImage {
  max-width: 40%;
}

.offerWrap {
  padding: 10px;
}

@media (min-width: 20em) {


  .offerHeader {
    font-size: 24px;
  }
  .offerText {
    font-size: 15px;
  }
  .priceAmount{font-size: 30px;}
  .priceAmount.right {
      padding: 25px 5px 10px 50px;
  }
  .priceAmount.left {
    padding: 10px 40px 15px 15px;
  }
  .priceSavings {
    font-size: 18px;
  }
  .mainImage {
    max-width: 45%;
  }
}
@media (min-width: 45em) {
  .price-amount sup.FFFFFF.no-dash,
  .price-amount sup.no-dash {
    top: -4px;
  }

  .offerHeader {
    font-size: 27px;
  }
  .offerText {
    font-size: 18px;
  }
  .priceAmount{font-size: 40px;}
  .priceAmount.right {
      padding: 25px 20px 10px 50px;
  }
  .priceAmount.left {
      padding: 40px 50px 10px 20px;
  }
  .priceSavings {
    font-size: 18px;
  }
  .mainImage {
    max-width: 100%;
  }
  .offerWrap {
    padding: 20px;
  }
}
@media (min-width: 60em) {
  .offerHeader {
    font-size: 33px;
  }
  .offerText {
    font-size: 22px;
  }
  .priceAmount{font-size: 45px;}
  .priceAmount.right {
      padding: 30px 20px 10px 50px;
  }
  .priceAmount.left {
      padding: 30px 30px 10px 20px;
  }
  .priceSavings {
    font-size: 18px;
  }
}

.modal-main {
  min-height: 90%;
}

@media (min-height: 20em) {
  .modal-main {
    min-height: 80%;
  }
}

@media (min-height: 40em) {
  .modal-main {
    min-height: 55%;
  }
}

@media (min-height: 60em) {
  .modal-main {
    min-height: 45%;
  }
}
